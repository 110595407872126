<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="auto"
    >
      <el-form-item :label="$t('user.newPassword')" prop="newPassword">
        <el-input
          v-model="form.newPassword"
          :maxlength="128"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('user.confrimPassword')" prop="confrimPassword">
        <el-input
          v-model="form.confrimPassword"
          :maxlength="128"
          show-password
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button
        class="footer-btn"
        type="primary"
        plain
        @click="handleClose"
      >{{ $t('cancel') }}</el-button>
      <el-button
        class="footer-btn"
        type="primary"
        @click="submit"
      >{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updatePassword } from '@/api/user'
export default {
  name: 'dialog-reset-password',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default() {
        return this.$t('user.addSubuser')
      }
    },
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    // 密码验证
    let passwordCheck = async (rule, value, callback) => {
      let pwdReg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{3,30}$/
      if (value === '') {
        return callback(new Error(this.$t('requireTips')))
      } else if (!pwdReg.test(value)) {
        return callback(new Error(this.$t('modifyPassword.passwordformat')))
      } else {
        callback()
      }
    }
    // 确认新密码不为空
    let confrimPasswordCheck = async (rule, value, callback) => {
      if (value.length < 1) {
        return callback(new Error(this.$t('modifyPassword.newPswRequire')))
      } else if (this.form.newPassword != value) {
        return callback(new Error(this.$t('modifyPassword.pswIsSame')))
      } else {
        callback()
      }
    }
    return {
      form: {
        newPassword: '',
        confrimPassword: '',
      },
      rules: {
        newPassword: {
          required: true,
          validator: passwordCheck,
          trigger: 'blur',
        },
        confrimPassword: {
          required: true,
          validator: confrimPasswordCheck,
          trigger: 'blur',
        },
      },
      roleList: [],
    }
  },
  methods: {
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            id: this.userId,
            userNewPwd: this.form.newPassword,
            method: 1  // 表示密码重置
          }
          updatePassword(data)
            .then(res => {
              this.$message.success(res.data.message)
              this.handleClose()
            })
        }
      })
    },
  },
}
</script>
