<template>
  <div class="user">
    <table-pagination
      memory="tabPaneUser"
      ref="refTable"
      :height="pageTabelHeight + 29"
      :tableData="tableData"
      :columnData="columnData"
      optionShow
      columnOptionShow
      :total="tabelTotal"
      :currentPage.sync="tabelSearch.currentPage"
      :pageSize.sync="tabelSearch.pageSize"
      @changeCurrentPage="getTabelData"
    >
      <!-- userName -->
      <template slot="userName" slot-scope="scope">
        <i
          class="el-icon-user-solid"
          :class="scope.row.isSubuser ? 'text-primary' : 'text-warning'"
        ></i>
        {{ scope.row.userName }}
      </template>
      <!-- userStatus -->
      <template slot="userStatus" slot-scope="scope">
        <span v-if="scope.row.userStatus == 1" class="text-warning">{{ $t('user.activate') }}</span>
        <span v-else class="text-success">{{ $t('user.inactivate') }}</span>
      </template>
      <!-- option -->
      <template slot="option" slot-scope="scope">
        <template v-if="scope.row.isSubuser">
          <el-tooltip
            effect="dark"
            :content="scope.row.userStatus ? $t('user.resetPaw') : $t('user.send')"
            placement="top"
            :open-delay="1000"
          >
            <el-button
              v-if="isLAN"
              type="text"
              class="btn-p0"
              @click="openResetPasswordDialog(scope.row)"
            >
              <i :class="scope.row.userStatus ? 'el-icon-refresh-right' : 'el-icon-message'"></i>
            </el-button>
            <el-button
              v-else
              type="text"
              class="btn-p0"
              @click="sendItem(scope.row)"
            >
              <i :class="scope.row.userStatus ? 'el-icon-refresh-right' : 'el-icon-message'"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip effect="dark" :content="$t('edit')" placement="top" :open-delay="1000">
            <el-button
            type="text"
            class="btn-p0"
            icon="el-icon-edit iconfont icon-edit"
            @click="editItem(scope.row)"
          ></el-button>
          </el-tooltip>
          <el-tooltip effect="dark" :content="$t('delete')" placement="top" :open-delay="1000">
            <el-button
              type="text"
              class="btn-p0"
              icon="el-icon-delete iconfont icon-delete"
              @click="deleteItem(scope.row.id, scope.row.userName)"
            ></el-button>
          </el-tooltip>
        </template>
      </template>
    </table-pagination>
    <dialog-add-user
      v-if="addUserVisible"
      :visible.sync="addUserVisible"
      type
      :title="$t('user.editSubuser')"
      :req="addUserData"
      @change="getTabelData"
    />
    <dialog-reset-password
      v-if="showResetPassword"
      :visible.sync="showResetPassword"
      :title="$t('user.resetPassword')"
      :userId="userId"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { TablePagination } from '@/components'
import DialogAddUser from './dialog-add-user'
import DialogResetPassword from './dialog-reset-password'
import { userSearch, removeUser, userSend } from '@/api/user'

export default {
  name: 'user-list',
  components: {
    TablePagination,
    DialogAddUser,
    DialogResetPassword
  },
  data() {
    return {
      addUserVisible: false,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'createTime',
        sortOrder: 'asc',
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('user.account'),
          prop: 'loginName',
          minWidth: 150,
        },
        {
          label: this.$t('user.email'),
          prop: 'email',
          minWidth: 200,
        },
        {
          label: this.$t('user.userName'),
          prop: 'userName',
          minWidth: 200,
          slot: true,
        },
        {
          label: this.$t('user.role'),
          prop: 'role',
          minWidth: 100,
        },

        {
          label: this.$t('user.status'),
          prop: 'userStatus',
          minWidth: 100,
          slot: true,
        },
      ],
      tableData: [],
      addUserData: {},
      // 重置密码
      showResetPassword: false,
      userId: null,
    }
  },
  computed: {
    ...mapState('session', ['isLAN']),
    ...mapGetters('session', ['pageTabelHeight']),
  },
  created() {
    this.getTabelData()
  },
  methods: {
    // 获取数据
    getTabelData() {
      userSearch(this.tabelSearch)
        .then(({ data }) => {
          this.tableData = data.result.rows[0].list // 表格数据
          this.tabelTotal = data.result.rows[0].total // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 编辑
    editItem({ id, userName, email, roleId }) {
      this.addUserVisible = true
      this.addUserData = {
        id,
        userName,
        email,
        roleId,
      }
    },
    // 发送
    sendItem({ id, email, userName, userStatus }) {
      userSend({
        id: id,
        email: email,
        userName: userName,
        type: userStatus ? 0 : 1,
      })
        .then((res) => {
          this.$message.success(res.data.message)
          this.getTabelData()
        })
        .catch(() => {})
    },
    // 删除
    deleteItem(id, userName) {
      this.$confirm(this.$t('user.userDeleteTip'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          removeUser({ id, userName })
            .then((res) => {
              this.$message.success(res.data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 打开重置密码弹窗
    openResetPasswordDialog({ id }) {
      this.userId = id
      this.showResetPassword = true
    }
  },
}
</script>

<style lang="scss" scoped>
.user {
  height: 100%;
  background-color: #FFF;
}
</style>