<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item :label="$t('user.userName')" prop="userName">
          <el-input
            v-model="form.userName"
            :placeholder="$t('user.userNamePla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <template v-if="isLAN && !type">
          <el-form-item :label="$t('user.userPwd')" prop="userPwd">
            <el-input
              v-model="form.userPwd"
              :placeholder="$t('user.userPwdPla')"
              :maxlength="128"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('user.confrimPassword')" prop="confrimPassword">
            <el-input
              v-model="form.confrimPassword"
              :placeholder="$t('user.userConfrimPla')"
              :maxlength="128"
              show-password
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item :label="$t('user.email')" prop="email">
          <el-input
            v-model="form.email"
            :placeholder="$t('user.emailPla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.role')" prop="roleId">
          <el-select
            v-model="form.roleId"
            style="width: 100%"
            :placeholder="$t('user.rolePla')"
          >
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
              :disabled="item.roleName == 'admin'"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button class="footer-btn" type="primary" plain @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { selectRole, addUser } from '@/api/user'

export default {
  name: 'dialog-add-user',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('user.addSubuser')
      },
    },
    req: Object,
    type: Boolean,  // F-添加 T-编辑
  },
  data() {
    // 邮箱验证
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('login.emailIsCorrectTip')))
        this.retrieveData = false
      } else {
        if (value !== '') {
          var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
          if (!reg.test(value)) {
            // callback(new Error('Please fill in valid email address'))
            callback(new Error(this.$t('login.emailIsValidTip')))
            this.retrieveData = false
          } else {
            this.retrieveData = true
          }
        }
        callback()
      }
    }
    return {
      form: {
        userName: '',
        email: '',
        roleId: null,
      },
      rules: {
        userName: {
          required: true,
          message: this.$t('user.userNameRule'),
          trigger: 'blur',
        },
        email: {
          required: true,
          validator: validateEmail,
          trigger: 'blur',
        },
        roleId: {
          required: true,
          message: this.$t('user.roleRule'),
          trigger: 'change',
        },
      },
      roleList: [],
    }
  },
  computed: {
    ...mapState('session', ['isLAN'])
  },
  created() {
    this.getRoleList()
    if (this.isLAN && !this.type) this.setLanForm()
    if (this.req) this.form = this.req
  },
  methods: {
    // 设置局端表单（增加密码设置）
    setLanForm() {
      this.$set(this.form, 'userPwd', '')
      this.$set(this.form, 'confrimPassword', '')
      // 密码验证
      let passwordCheck = async (rule, value, callback) => {
        let pwdReg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{3,30}$/
        if (value === '') {
          return callback(new Error(this.$t('user.userPwdEmpty')))
        } else if (!pwdReg.test(value)) {
          return callback(new Error(this.$t('modifyPassword.passwordformat')))
        } else {
          callback()
        }
      }
      // 确认新密码不为空
      let confrimPasswordCheck = async (rule, value, callback) => {
        if (value.length < 1) {
          return callback(new Error(this.$t('user.userPwdEmpty')))
        } else if (this.form.userPwd != value) {
          return callback(new Error(this.$t('modifyPassword.pswIsSame')))
        } else {
          callback()
        }
      }
      this.rules.userPwd = {
        required: true,
        validator: passwordCheck,
        trigger: 'blur',
      }
      this.rules.confrimPassword = {
        required: true,
        validator: confrimPasswordCheck,
        trigger: 'blur',
      }
    },
    // 获取角色列表
    getRoleList() {
      selectRole()
        .then(({ data }) => {
          this.roleList = data.result.rows
        })
        .catch(() => {})
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          addUser(this.form)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.$emit('update:visible', false)
              this.$emit('change')
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
