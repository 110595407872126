<template>
  <div class="page">
    <div class="page-header">
      <el-button
        v-if="activeName == 'user'"
        type="primary"
        icon="el-icon-plus"
        @click="addUserItem"
      >{{ $t('user.addSubuser') }}</el-button>
      <el-button
        v-else
        type="primary"
        icon="el-icon-plus"
        @click="addRoleItem"
      >{{ $t('user.addRole') }}</el-button>
    </div>
    <div class="page-main bg-transparent">
      <el-tabs v-model="activeName" @tab-click="changeTab">
        <el-tab-pane :label="$t('user.user')" name="user">
          <tab-pane-user ref="tabPaneUser"></tab-pane-user>
        </el-tab-pane>
        <el-tab-pane :label="$t('user.role')" name="role">
          <tab-pane-role ref="tabPaneRole"></tab-pane-role>
        </el-tab-pane>
      </el-tabs>
    </div>
    <dialog-add-user
      v-if="addUserVisible"
      :visible.sync="addUserVisible"
      @change="userChange"
    ></dialog-add-user>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabPaneUser from './tab-pane-user'
import TabPaneRole from './tab-pane-role'
import DialogAddUser from './dialog-add-user'

export default {
  name: 'User',
  components: {
    TabPaneUser,
    TabPaneRole,
    DialogAddUser
  },
  data() {
    return {
      activeName: 'user',
      addUserVisible: false
    }
  },
  computed: {
    ...mapState('memory', ['userTab'])
  },
  created() {
    if (this.userTab) this.activeName = this.userTab
  },
  beforeRouteLeave (to, from, next) {
    // 前往地址不是 role 相关，重置成 user
    if (to.name !== 'addRole') this.$store.commit('memory/SET_USER_TAB', 'user')
    next()
  },
  methods: {
    changeTab() {
      this.$store.commit('memory/SET_USER_TAB', this.activeName)
    },
    addUserItem() {
      this.addUserVisible = true
    },
    userChange() {
      this.$refs.tabPaneUser.getTabelData()
    },
    addRoleItem() {
      this.$router.push({ path: '/user/addRole' })
    }
  }
}
</script>
