<template>
  <div class="role">
    <table-pagination
      memory="tabPaneRole"
      ref="refTable"
      :height="pageTabelHeight + 29"
      :tableData="tableData"
      :columnData="columnData"
      optionShow
      columnOptionShow
      :total="tabelTotal"
      :currentPage.sync="tabelSearch.currentPage"
      :pageSize.sync="tabelSearch.pageSize"
      @changeCurrentPage="getTabelData"
    >
      <!-- option -->
      <template slot="option" slot-scope="scope">
        <el-tooltip
          v-if="scope.row.roleName != 'admin'"
          effect="dark"
          :content="$t('edit')"
          placement="top"
          :open-delay="1000"
        >
          <el-button
            type="text"
            class="btn-p0"
            icon="el-icon-edit iconfont icon-edit"
            @click="editItem(scope.row.id)"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          v-if="scope.row.roleName != 'admin'"
          effect="dark"
          :content="$t('delete')"
          placement="top"
          :open-delay="1000">
          <el-button
            type="text"
            class="btn-p0"
            icon="el-icon-delete iconfont icon-delete"
            @click="deleteItem(scope.row)"
          ></el-button>
        </el-tooltip>
      </template>
    </table-pagination>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { TablePagination } from '@/components'
import { roleSearch, removeRole } from '@/api/user'

export default {
  name: 'user-role',
  components: {
    TablePagination,
  },
  computed: {
    ...mapState('account', ['loginInfo']),
    ...mapGetters('session', ['pageTabelHeight']),
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'createTime',
        sortOrder: 'asc',
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('user.roleName'),
          prop: 'roleName',
          minWidth: 150,
        },
        {
          label: this.$t('user.roleDes'),
          prop: 'roleDescription',
          minWidth: 150,
        },
      ],
      tableData: [],
      addRoleData: {},
    }
  },
  created() {
    this.getTabelData()
  },
  methods: {
    getTabelData() {
      roleSearch(this.tabelSearch)
        .then(({ data }) => {
          this.tableData = data.result.rows[0].list // 表格数据
          this.tabelTotal = data.result.rows[0].total // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    editItem(id) {
      this.$router.push({path: '/user/editRole', query: {id}})
    },
    deleteItem({ id, roleName }) {
      this.$confirm(this.$t('user.roleDeleteTip'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          removeRole({ id: id.toString(), args: roleName })
            .then((res) => {
              this.$message.success(res.data.message)
              this.getTabelData()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.role {
  height: 100%;
  background-color: #FFF;
}
</style>